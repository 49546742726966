body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.ant-layout {
  background-color: white;
}

.app-notification-error{
  background-color: #ffe9e9;

}

.app-notification-success .ant-notification-notice-message{
  color: #ffffff !important;
}

.app-notification-success .ant-notification-notice-description{
  color: #ffffff;
}
.app-notification-success{
  background-color: #ffe9e9;
}
